import React from "react"
import { Container } from "react-bootstrap"

import Caption from "components/Caption/Caption"

import Chart from "images/ramses-card-a-desktop-chart.svg"
import MobileChart from "images/ramses-card-a-mobile-chart.svg"

import styles from "./RamsesCardA.module.scss"

const RamsesCardA = ({ header, topicsA, topicsBHeader, topicsB, caption1, caption2  }) => {
  return (
    <Container id={"design"} as={"section"} className={styles.cardContainer}>
      <h2 className={styles.header}>
        {header.title}
        {header.references.length > 0 && (
          <sup className={styles.references}>{header.references.join(",")}</sup>
        )}
      </h2>
      <ul className={styles.topics}>
        {topicsA.map(({ content, references }, index) => {
          return (
            <li className={styles.topic} key={index}>
              {content}
              {references.length > 0 && (
                <sup className={styles.references}>{references.join(",")}</sup>
              )}
            </li>
          )
        })}
      </ul>
      <Caption>{caption1.title}</Caption>
      <img className={styles.chart} alt="RAMSES trial design: 506 participants aged 4 through 17 years; highly peanut sensitive; of the intent-to treat population, 337 participants randomized to PALFORZIA,168 participants randomized to placebo" src={Chart} />
      <img className={styles.mobileChart} alt="RAMSES trial design: 506 participants aged 4 through 17 years; highly peanut sensitive; of the intent-to treat population, 337 participants randomized to PALFORZIA,168 participants randomized to placebo" src={MobileChart} />
      <h3 className={styles.topicsHeader} id="results">
        {topicsBHeader.title}
        {topicsBHeader.references.length > 0 && (
          <sup className={styles.references}>
            {topicsBHeader.references.join(",")}
          </sup>
        )}
      </h3>
      <ul className={styles.topics}>
        {topicsB.map(({ content }, index) => {
          return (
            <li className={styles.topic} key={index}>
              {content}
            </li>
          )
        })}
      </ul>
      <Caption subtext={caption2.title}>
          {caption2.header.title}
          {caption2.header.references.length > 0 && (
            <sup className={styles.references}>
              {caption2.header.references.join(",")}
            </sup>
          )}
        </Caption>
      <p className={styles.footer}>
        DBPCFC = double-blind, placebo-controlled food challenge; psIgE =
        peanut-specific immunoglobulin E; RAMSES = <strong>R</strong>eal-World{" "}
        <strong>A</strong>R101 <strong>M</strong>arket <strong>S</strong>
        upporting <strong>E</strong>xperience <strong>S</strong>tudy.
      </p>
    </Container>
  )
}

export default RamsesCardA
