import React from "react"

import classNames from "classnames"

import styles from "./Caption.module.scss"

const Caption = ({ children, subtext }) => {
  return (
    <summary
      className={classNames([styles.caption], {
        [styles.captionSubtext]: subtext,
      })}
    >
      {children}
      {subtext && <p className={styles.subtext}>{subtext}</p>}
    </summary>
  )
}

export default Caption
