export const useRamsesData = ({ referencesHeader }) => {
  const HEADER = {
    title:
      "RAMSES: A clinical trial for peanut allergy assessing safety",
    references: [1],
  }

  const TABS = [
    {
      label: "Study Design",
      href: "#design",
    },
    {
      label: "Study Results",
      href: "#results",
    },
  ]

  const CARD_A = {
    header: {
      title:
        "RAMSES was a phase 3, multicenter, double-blind, placebo-controlled randomized study",
      references: [1],
    },
    topicsA: [
      {
        content:
          "Designed to gain experience with PALFORZIA in a real-world setting, without requirement of a DBPCFC for study entry",
        references: [],
      },
      {
        content:
          "All participants were monitored for adverse events through the approximate 6-month study duration",
        references: [1],
      },
    ],
    topicsBHeader: {
      title: "Highly peanut-sensitive participants",
      references: [1],
    },
    topicsB: [
      {
        content:
          "Clinical history of peanut allergy, including onset of symptoms within 2 hours of known oral peanut exposure",
      },
      { content: "psIgE of ≥14 kUA/L" },
      { content: "Mean wheal diameter on skin-prick test ≥8 mm greater than negative control" },
    ],
    caption1: {
      title:
        "Participants were NOT required to complete a DBPCFC for study entry.",
    },
    caption2: {
      header: {
        title: "Primary endpoint",
        references: [2],
      },
      title:
        "Frequency of treatment-emergent adverse events, including serious adverse events, during the overall study period",
    },
    footnotes:
      "DBPCFC = double-blind, placebo-controlled food challenge; psIgE = peanut-specific immunoglobulin E; RAMSES = Real-World AR101 Market Supporting Experience Study in Peanut-Allergic Children.",
  }

  const CARD_B = {
    header: {
      title: "RAMSES study results",
      references: [],
    },
    topics: [
      {
        content:
          "In RAMSES, no treatment-emergent adverse events ≥5% were reported in participants following daily treatment with 300 mg of PALFORZIA (n=265)",
        references: [1],
      },
    ],
    caption: {
      header: {
        title: "Primary endpoint",
        references: [2],
      },
      title:
        "Frequency of treatment-emergent adverse events, including serious adverse events, during the overall study period",
    },
  }

  return {
    header: { ...HEADER },
    tabs: [...TABS],
    cardA: { ...CARD_A },
    cardB: { ...CARD_B },
    referencesHeader: referencesHeader.title,
  }
}
